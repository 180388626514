.container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

h1 {
  font-size: 2.5em;
}

.logo {
  display: flex;
  max-width: 991px;
  justify-content: flex-start;
  width: 100%;
}

.Header {
  width: 100%;
  display: flex;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  background-color: #EEE;
  justify-content: center;
}

.image {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}

.Hero {
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  display: flex;
  min-height: 50vh;
  /* max-width: 1200px; */
  justify-content: center;
}

.container1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  max-width: 60%;
  padding-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
}

.container1Event {
  display: flex;
  flex-direction: row;
}

.container1Event span {
  flex-shrink: 1;
  margin: auto 0;

}

.container1Event div {
  flex-grow: 1;
}

.container1Desc {
  flex-grow: 1;
}

.container1Desc div {
  margin-top: 6em;
}

.container1Desc span ol li:not(:last-child) { 
  margin-bottom: 10px;  
}

.container1Desc div div { 
  font-size: 10pt;
  margin-bottom: 10px;
}

.container1Desc div em ul { 
  font-size: 10pt;
}

.container1Desc div em ul li { 
  margin-bottom: 5px;
}

.container2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  max-width: 60%;
  padding-right: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
}

.text1 {
  margin-top: var(--dl-space-space-twounits);
  text-align: left;
  margin-bottom: var(--dl-space-space-twounits);
}

.form {
  width: var(--dl-size-size-xxlarge);
  position: relative;
  align-self: center;
}

.textinput {
  width: 100%;
  padding: 0.7rem;
  margin-bottom: 1rem;
}

.fileinput {
  width: 100%;
  padding: 0.7rem;
  margin-bottom: 10px;
}

.button {
  display: flex;
  margin-top: 1rem;
  transition: 0.3s;
  margin-bottom: 0.5rem;
  background-color: cadetblue;
  color: white;
  border: none;
  cursor: pointer;
}

.button:hover {
  transform: scale(1.02);
}

.continue {
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
}

.success-content {
  text-align: justify;
  line-height: 3;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 2em;
}

.success-content p:nth-of-type(1) {
  flex-grow: 1;
}

.success-content p:nth-of-type(2) {
  flex-grow: 0;
  line-height: 2;
  font-size: 8pt;
}

.req {
  color: red;
}

@media(max-width: 991px) {

  .Header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .logo {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .Hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

}

@media(max-width: 767px) {
  .Header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .Hero {
    flex-direction: column;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .container2 {
    justify-content: flex-start;
    max-width: 100%;
  }

  .container1 {
    justify-content: flex-start;
    max-width: 100%;
  }
}

@media(max-width: 479px) {
  .Header {
    padding: var(--dl-space-space-unit);
  }

  .logo {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .Hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }

  .container1 {
    margin-bottom: var(--dl-space-space-unit);
  }

  .container1Event {
    display: flex;
    flex-direction: column;
  }

  .container1Event div {
    margin-top: 0.5em;
  }

  .button {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
}